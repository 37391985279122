import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { TC } from 'src/app/models/app.models';
import { ShopService } from 'src/app/services/shop.service';
import { storeIdFix } from 'src/app/utils/storeId-fix.interface';
import Swal from 'sweetalert2';

@Component({
  selector: 'best-popup-terms-conditions-fix',
  templateUrl: './popup-terms-conditions-fix.component.html',
  styleUrls: ['./popup-terms-conditions-fix.component.scss']
})
export class PopupTermsConditionsFixComponent implements OnInit, OnDestroy {
  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;


  @Input('setData') set setData(data: { display: boolean }) {
    if (data && data.display) {
      this.display = true;
    }
  }
  @Output('output') output = new EventEmitter<any>();

  display: boolean = false;
  checkScroll: boolean = false;
  shop: any;
  Subscriptions: Subscription[] = [];
  storeIdFix = storeIdFix;
  textTermsConditions: string;
  termsConditions: SafeHtml;


  constructor(
    public shopService: ShopService,
    private sanitizer: DomSanitizer,
    private storage: LocalStorageService,
  ) {
    this.Subscriptions.push(
      this.shopService.store.subscribe(res => {
        this.shop = res;
        if (res && res.Id === this.storeIdFix.StoreIdNumber1) {
          this.getTC();
        }
      })
    );
  }

  ngOnDestroy() {
    this.Subscriptions.map(S => S.unsubscribe());
    this.Subscriptions = [];
  }

  ngOnInit() {

  }

  /** ดึงข้อมูล terms and conditions */
  getTC() {
    this.shopService.getTermsAndConditions(this.shop.Id).subscribe(
      (res: any) => {
        this.textTermsConditions = res.text;
        this.termsConditions = this.sanitizer.bypassSecurityTrustHtml(this.textTermsConditions);
        requestAnimationFrame(() => this.onScroll());
      }, err => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          toast: true,
          showConfirmButton: false,
          timer: 1500,
          text: `${err.error.Message}`
        });
      }
    );
  }

  /** เชคการเลื่อนอ่าน termsAndConditions */
  onScroll(): void {
    const el = this.scrollContainer.nativeElement;
    if (el) {
      // เช็คว่ามีการเลื่อนเกิดขึ้นได้หรือไม่
      const canScroll = el.scrollHeight > el.clientHeight;

      if (canScroll && el.scrollTop + el.clientHeight >= el.scrollHeight) {
        this.checkScroll = true;
      }
    }
  }

  onSubmit() {
    this.storage.set(TC, `ture`);
    this.onClose(true);
  }

  onClose(status: boolean) {
    if (status) {
      this.output.emit(true);
    } else {
      this.output.emit(false);
    }
    this.display = false;
  }

}
