<p-dialog [(visible)]="display" styleClass="order-payment" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" (onHide)="onClose(false)" [resizable]="false">
    <p-header>{{'TERMS_AND_CONDITOINS' | language}}</p-header>
    <div class="compo-tc mb-3" *ngIf="shop && shop.Id === storeIdFix.StoreIdNumber1">
        <div class="ql-editor w-100" #scrollContainer (scroll)="onScroll()">
            <div [innerHTML]="termsConditions"></div>
        </div>
    </div>
<div class="d-flex flex-wrap justify-content-between my-3">
    <button class="btn btn-light mr-3 mb-3" (click)="onClose(false)">{{ 'BACK' | language }}</button>
    <button 
        class="btn btn-primary mb-3" 
        [disabled]="!checkScroll" 
        (click)="onSubmit()">
        {{ 'ACCEPT_AND_LOG_IN' | language }}
    </button>
</div>
</p-dialog>