import { Component, AfterViewInit, Inject, PLATFORM_ID, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { LoadScriptsService } from './services/load-scripts.service';
import { Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import AOS from 'aos';
import { AppService } from './app.service';
import { LocalStorageService } from './core/services/local-storage.service';
import { KEY_TOKEN, TC } from './models/app.models';
import { Subscription } from 'rxjs';
import { ShopService } from './services/shop.service';
import { storeIdFix } from './utils/storeId-fix.interface';

@Component({
  selector: 'kfa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  title = 'DeeAuction';
  displayTC:boolean = false;
  shop: any;
  Subscriptions: Subscription[] = [];
  storeIdFix = storeIdFix;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private loadScripts: LoadScriptsService,
    private svTitle: Title,
    private svApp: AppService,
    private storage: LocalStorageService,
    private _router: Router,
    public shopService: ShopService,
  ) {
    this.loadScripts.loadScripts().then(() => { });
    this.listenRoutes();
  }

  ngOnDestroy(): void {
    this.Subscriptions.map(s => s.unsubscribe());
    this.Subscriptions = [];
  }

  ngOnInit(): void {
    this.Subscriptions.push(
      this.shopService.store.subscribe(res => {
        let checkTC = this.storage.get(TC);
        if (res) {
          if (res && res.Id === this.storeIdFix.StoreIdNumber1) {
            if (this.storage.get(KEY_TOKEN) && !checkTC) {
              this.displayTC = true;
            }
          }
        }
      })
    );
    
  }

  ngAfterViewInit() {
    AOS.init();
  }

  listenRoutes() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let route = this.route.firstChild;
          let child = route;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
              route = child;
            } else {
              child = null;
            }
          }
          return route;
        }),
        mergeMap(route => route.data)
      )
      .subscribe(data => {
        if (this.svApp.isBrowser) {
          setTimeout(() => {
            AOS.refreshHard();
          }, 1000);
        }

        if (data.title) {
          this.svTitle.setTitle(data.title);
        } else {
          if (typeof data.title === 'string') {
            this.svTitle.setTitle(this.title);
          }
        }
      });
  }

  closePopupTC($event: boolean) {
    this.displayTC = false;
    let checkTC = this.storage.get(TC);
    if (!$event && !checkTC) {
      this._router.navigate(['']);
    }
  }

}
