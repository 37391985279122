import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService } from '../core/services/local-storage.service';
import { KEY_USER } from '../models/app.models';

@Injectable({
  providedIn: 'root'
})

export class ShopService {
  store = new BehaviorSubject<IStore>(null);
  public loading = new Subject<any>();
  public alertVerifyUser = new Subject<{name: string, value: any}>();
  public checkVerifyUser = new Subject<boolean>();
  
  private minishop:{Name: string,Logo: string,URL: string};
  

  constructor(private http: HttpClient, private localStorage: LocalStorageService) {}

  creatStore(item) {
    return this.http.post('store/create', item);
  }

  duplicateCheckNameStore(form) {
    return this.http.post(`store/nameduplicate`, {StoreName: form.Name});
  }

  duplicateCheckURLStore(form) {
    return this.http.post(`store/urlduplicate`, {UrlName: form.URL});
  }

  getListStore(form) {
    return this.http.get<IPagination<IStore>>(`store/hall/pagination?&page=${form.page}&limit=${form.limitRow}`);
  }

  getStoreByUrl(url) {
    return this.http.get<IStore>(`store/${url}`);
  }

  getStoreSelf() {
    return this.http.get<IStore>(`store/byowner`);
  }

  changeStatusStore(id, item) {
    return this.http.put(`store/enabledisable/${id}`, item);
  }

  updateMyStore(id, item) {
    return this.http.put(`store/update/${id}`, item);
  }

  getProductCategory(id) {
    return this.http.get(`productcategory/bystore/${id}`);
  }

  creatProductCategory(item) {
    return this.http.post('productcategory/create', item);
  }

  updateProductCategory(id, item) {
    return this.http.put(`productcategory/update/${id}`, item);
  }

  removeProductCategory(id, item) {
    return this.http.put(`productcategory/remove/${id}`, item);
  }

  getRecommendedProducts(url, productId) {
    return this.http.get(`product/recommend?url=${url}&productId=${productId}`);
  }

  getProductByOwner(form) {
    return this.http.get(
      `product/byowner?storeId=${form.StoreId}&status=${form.Status}&productcategoryId=${form.ProductCategoryId}&&page=${form.page}&limit=${form.limitRow}`
    );
  }

  getProductByStore(form) {
    return this.http.get<any>(
      `product/bystore?url=${form.URL}&productcategoryId=${form.ProductCategoryId}&filter=${form.filter}&&page=${form.page}&limit=${form.limitRow}`
    );
  }

  getProductDetailOwner(productCode) {
    return this.http.get<any>(`product/bycode/forowner/${productCode}`);
  }

  getProductDetail(url, productCode) {
    return this.http.get<any>(`product/bycode?url=${url}&productCode=${productCode}`);
  }

  creatProduct(item) {
    return this.http.post('product/create', item);
  }

  duplicateProductCode(form) {
    return this.http.get(`product/codeduplicate/${form.Code}`);
  }

  duplicateProductName(form) {
    return this.http.get(`product/nameduplicate?productName=${form.Name}&productId=${form.Id}`);
  }

  updateProduct(id, item) {
    return this.http.put(`product/update/${id}`, item);
  }

  disableProduct(id, item) {
    return this.http.put(`product/enabledisable/${id}`, item);
  }

  removeProduct(id, item) {
    return this.http.put(`product/remove/${id}`, item);
  }

  addChartOrder(item) {
    return this.http.post(`orders`, item);
  }

  getListCustomerVoucher() {
    return this.http.get(`voucher/customerlist`);
  }

  getListVoucherOwner(status, formDiscount) {
    return this.http.get(`voucher/bystore/${status}?&page=${formDiscount.page}&limit=${formDiscount.limitRow}`);
  }

  getListVoucher(storeUrl, formDiscount) {
    return this.http.get(`voucher/bycustomer/${storeUrl}?&page=${formDiscount.page}&limit=${formDiscount.limitRow}`);
  }

  createVoucher(item) {
    return this.http.post(`voucher/create`, item);
  }

  removeVoucher(voucherId, item) {
    return this.http.put(`voucher/remove/${voucherId}`, item);
  }

  addCustomerMapping(voucherId, item) {
    return this.http.put(`voucher/customermapping/${voucherId}`, item);
  }

  updateStockQuantity(productId, form, item = null) {
    return this.http.put(
      `product/update/stock?productId=${productId}&quantity=${form.quantity}&remark=${form.remark}`,
      item
    );
  }

  getListStockTransaction(productId, formPagi) {
    return this.http.get(`product/stocktransaction/${productId}?&page=${formPagi.page}&limit=${formPagi.limitRow}`);
  }

  getListBanks() {
    return this.http.get('banks');
  }

  getCustomerPayment() {
    return this.http.get(`customers/payment_methods`);
  }

  getPayment() {
    return this.http.get(`customers/payment_methods/store`);
  }

  createPayment(item) {
    return this.http.post(`customers/payment_methods/store`, item);
  }

  updatePayment(paymentMethodId, item) {
    return this.http.put(`customers/payment_methods/${paymentMethodId}`, item);
  }

  deleteIdPayment(paymentMethodId, item = null) {
    return this.http.delete(`customers/payment_methods/${paymentMethodId}`, item);
  }

  getAllMessageCustomerStore(search) {
    return this.http.get(`storemessage/allmessage/bystore?search=${search}`);
  }

  getMessageCustomerStore(id) {
    return this.http.get(`storemessage/bystore/${id}`);
  }

  sendMessageStoreOwner(item) {
    return this.http.post(`storemessage/send`, item);
  }

  removeMessage(messageId, item) {
    return this.http.post(`storemessage/remove/${messageId}`, item);
  }

  getMessageCustomer(url) {
    return this.http.get(`storemessage/bycustomer/${url}`);
  }

  readMessageCustomer(url, item) {
    return this.http.post(`storemessage/read/bycustomer/${url}`, item);
  }

  readMessageOwner(id, item) {
    return this.http.post(`storemessage/read/bystore/${id}`, item);
  }

  shopAuctionListOwner(storeURL, formAuction) {
    return this.http.get(
      `auction/table/bystore?storeURL=${storeURL}&auctiontypeId=${formAuction.AuctionTypeId}&status=${formAuction.Status}&page=${formAuction.page}&limit=${formAuction.limitRow}`
    );
  }

  /** api get หมวดหมู่ */
  GetCategoryCar(searchName: string) {
    return this.http.get<any>(`productattributes/category/list?searchName=${searchName}`);
  }

  /** api get ประเภทรถ */
  GetTypevehicleCar(searchName: string) {
    return this.http.get<any>(`productattributes/typevehicle/list?searchName=${searchName}`);
  }

  /** api get เกียร์รถ */
  GetGearCar(searchName: string) {
    return this.http.get<any>(`productattributes/gear/list?searchName=${searchName}`);
  }

  /** api get เชื้อเพลิงรถ */
  GetFuelCar(searchName: string) {
    return this.http.get<any>(`productattributes/fuel/list?searchName=${searchName}`);
  }

  /** api get ยี่ห้อ */
  GetBrandCar(searchName: string, categoryCarId: string) {
    return this.http.get<any>(`productattributes/brand/list?searchName=${searchName}&categoryCarId=${categoryCarId}`);
  }

  /** api get รุ่น */
  GetModelCar( brandId: number,searchName: string) {
    return this.http.get<any>(`productattributes/model/list?brandId=${brandId}&searchName=${searchName}`);
  }

  /** api get สีรถ  */
  GetColorCar(searchName: string) {
    return this.http.get<any>(`productattributes/color/list?searchName=${searchName}`);
  }

  /** api get จังหวัดที่ลงขาย */
  GetProvince(searchName: string) {
    return this.http.get<any>(`productattributes/province/list?searchName=${searchName}`);
  }

  /** api get อำเภอที่ลงขาย */
  GetDistrict(provinceId: number ,searchName: string) {
    return this.http.get<any>(`productattributes/district/list?provinceId=${provinceId}&searchName=${searchName}`);
  }

  /** api get คุณลักษณะ  */
  GetAttributesCar(searchName: string) {
    return this.http.get<any>(`productattributes/attributes/list?searchName=${searchName}`);
  }

  /** สร้างรหัสยืนยันหมายเลยโทรศัพท์ */
  GetOTPPhone(countryCode: number, phoneNo: string) {
    const str = phoneNo.split(' ');
    let phone = '';
    str.forEach(data => phone += data );
    return this.http.post<any>(`sellproductverificationotp/create`, {CountryCode: countryCode, PhoneNo: phone} );
  }

  /** อัปเดท otp */
  UpdateOTP(output: {CountryCode: number, PhoneNo: string, OTPRef: string, OTPCode: string }) {
    return this.http.put<any>(`sellproductverificationotp/update`, output);
  }

  /** สร้างสินค้า */
  CreateProduct(product: any) {
    return this.http.post<any>(`product/create`, product);
  }

  /** อัปเดทสินค้า */
  UpdateProduct(product: any,productId: number) {
    return this.http.put<any>(`product/update/${productId}`, product);
  }

  /** ปิดการขายสินค้า */
  soldProduct(productId: number) {
    return this.http.put(`product/soldout/${productId}`,{productId: productId });
  }

  /** ส่งหลักฐานขอยืนยันร้านค้า */
  verifyShop( storeId: number,
              StoreRegImg: {StoreRegImgType: string,StoreRegImg: string}[]
            ) {
    return this.http.put(`store/requestregisterstore/${storeId}`,{StoreRegImg: StoreRegImg });

  }

  /** set ข้ิมูลร้านค้านั้นๆที่กำลังจะเปิด */
  setMiniShop(dataSet: {Name: string,Logo: string,URL: string}) {
    this.minishop = dataSet;
  }

  /** minishop ที่จะถูกเอาไปใช้ตอนเปิดร้านค้านั้นๆ */
  get getMiniShop() {
    return this.minishop;
  }

  /** เชคว่าร้านที่เข้าอยู่เรามีการยืนยันตัวตนอ่ะยังง */
  customerInfo(store_url: string) {
    return this.http.get(`customer/info?store_url=${store_url}&timer=${Date.now()}`).pipe(
      map(customer => {
        this.localStorage.set(KEY_USER, customer);
        return customer;
      })
    );

  }

  /** ลูกค้าดูข้อมูล verify ของตัวเอง */
  getUserVerify(url_name: string) {
    return this.http.get(`storemember/verifyevidence?url_name=${url_name}`);
  }

  /** ส่งของมูล verify ของลูกค้า */
  putUserVerify(output: any) {
    return this.http.put(`storemember/verifyevidence/submit`, output);
  }

  /** ค่ารวม 4 list รายการประมูล */
  countAuction(storeURL: string) {
    return this.http.get(`auction/counttype/bystore?storeURL=${storeURL}&date=${new Date()}`);
  }

  /** ดึงข้อมูล terms and conditions ของร้านค้า */
  getTermsAndConditions(storeId: number) {
    return this.http.get(`store/condition/${storeId}`);
  }

}
